import React from 'react'
import SEO from '../seo'
import { useStaticQuery, graphql } from "gatsby"

const PageSEO = ({ path, title, intro }) => {

  const data = useStaticQuery(graphql`
    query {
      allSanityMenu {
        edges {
          node {
            links {
              description
              title
              url
            }
          }
        }
      }
    }
  `)

  const this_path = '/' + path.replaceAll('/', '');
  const all_links = data.allSanityMenu.edges[0].node.links;
  const this_description = all_links.filter(f => f.url === this_path)[0]?.description || intro;
  const this_title = all_links.filter(f => f.url === this_path)[0]?.title || title;

  return (
    <SEO title={this_title} slug={this_path} description={this_description} />
  )
}

export default PageSEO
