import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { slugify, artistname } from '../../lib/utils'

const Container = styled.div`
  display: flex;
  min-height: 215px;
  justify-content: center;
  align-items: flex-start;
  h1 {
    color: ${props => props.black ? '#000' : '#EE2880'};
    text-align: center;
    padding-top: 62px;
  }
  @media(max-width: 880px) {
    min-height: 0;
    max-width: 100vw;
    h1 {
      padding-top: 20px;
    }
  }
 `

const Inner = styled.div`
  text-align: center;
  border-bottom: ${props => props.noborder ? 'none' : '1px solid #ebeaea'};
  width: 100%;
  padding-bottom: ${props => props.paddingbottom ? '35px' : '0px'};
  max-width: 940px;
  p {
    margin-top: 8px;
  }
`

const Subtitle = styled.p`
  a {
    font-weight: 400;
  }
  span {
    margin-left: 0.35rem;
    margin-right: 0.35rem;
  }
`

const Title = ({ children, related_artists, noborder, black, subtitle, paddingbottom = true }) => {

  const headline = children ? children.replaceAll('*', '★') : '';

  return (
    <Container black={black}>
      <Inner noborder={noborder} paddingbottom={paddingbottom}>
        <h1>{headline}</h1>
        {subtitle &&
          <Subtitle>
            {subtitle}
            {related_artists && related_artists.length > 0 &&
              related_artists.map((r, index) => {
                const artist_fullname = (r.first_name + ' ' + r.surname).trim();
                const artist_link = '/artists/' + slugify(artist_fullname);

                const artist_name = artistname(r);

                return (
                  <React.Fragment key={index}><span>|</span><Link to={artist_link}>{artist_name}</Link></React.Fragment>
                )
              })
            }
          </Subtitle>
        }
      </Inner>
    </Container>
  )
}

export default Title;
