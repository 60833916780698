import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: ${props => props.colgap};
  grid-row-gap: ${props => props.rowgap};
  max-width: ${props => props.maxwidth};
  margin-left: auto;
  margin-right: auto;
  margin-top: ${props => props.margintop};
  padding-bottom: 39px;
  @media (max-width: 1000px) {
    max-width: calc(100vw - 2rem);
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 4rem;
  }
  @media (max-width: 640px) {
    max-width: calc(100vw - 2rem);
    grid-template-columns: repeat(1, 1fr);
  }
`

const Grid = ({ children, mt = '0px', maxwidth = '1400px', colgap = '0px', rowgap = '0px' }) => {

  return (
    <Container
      margintop={mt}
      rowgap={rowgap}
      colgap={colgap}
      maxwidth={maxwidth}>
      {children}
    </Container>
  )
}

export default Grid;
