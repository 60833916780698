import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Tooltip } from 'react-tooltip'
import { blockContentToPlainText } from "react-portable-text"
import Grid from '../grid'

const Person = styled.div`
  display: flex;
  flex-direction: column;
  h4 {
    text-align: center;
    font-size: 42px;
    line-height: 44px;
    display: inline-block;
    transition: all 0.25s ease-in-out;
    &:hover {
      color: var(--pink);
    }
  }
  a {
    font-family: Catamaran, sans-serif;
    text-align: center;
    text-decoration: none;
    font-size: 19px;
    line-height: 26px;
    font-weight: 300;
  }
`

const People = () => {

  const data = useStaticQuery(graphql`
    query {
      allSanityPeopleOrder {
        edges {
          node {
            title
            pbj_people {
              website
              twitter
              title
              slug {
                current
              }
              headshot {
                ...ImageWithPreview
              }
              _rawBiographyBlocks(resolveReferences: {maxDepth: 10})
              biography_blocks {
                _rawChildren
                _type
                list
                style
                children {
                  _key
                  _type
                  text
                  marks
                 }
              }
              shorter_biography
              email
              facebook
              instagram
              phone
            }
          }
        }
      }
    }
  `)

  const people = data.allSanityPeopleOrder.edges[0].node.pbj_people;

  function truncate(str, n, useWordBoundary) {
    var singular, tooLong = str.length > n;
    useWordBoundary = useWordBoundary || true;

    // Edge case where someone enters a ridiculously long string.
    str = tooLong ? str.substr(0, n - 1) : str;

    singular = (str.search(/\s/) === -1) ? true : false;
    if (!singular) {
      str = useWordBoundary && tooLong ? str.substr(0, str.lastIndexOf(' ')) : str;
    }

    return tooLong ? str + '...' : str;
  }


  return (
    <Grid colgap="7px" rowgap="61px" mt="76px">
      <Tooltip id="person-tooltip" style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', opacity: '0.99', border: '1px solid #fff', padding: '1.5rem', maxWidth: '300px', backgroundColor: '#EE2880', fontFamily: 'Catamaran, sans-serif' }} />
      {people.map((p, index) => {

        const block_bio_text = p.biography_blocks ? blockContentToPlainText(p.biography_blocks) : '';
        const direction = 'bottom';
        const bio = p.shorter_biography ? p.shorter_biography : block_bio_text;
        const trimmed_bio = bio ? truncate(bio, 236, true) : false

        return (
          <Person key={index}>
            <Link to={`/people/${p.slug.current}`}
              data-tooltip-id="person-tooltip"
              data-tooltip-offset={4}
              data-tooltip-content={trimmed_bio}
              data-tooltip-place={direction}>
              <h4>{p.title}</h4>
            </Link>
            <a href={`mailto:${p.email}`}>{p.email}</a>
          </Person>
        )
      })}
    </Grid>
  )
}

export default People;
