import React from 'react'
import Title from '../components/title'
import People from '../components/people'
import PageSEO from '../components/page_seo'

const PagePeople = () => {
  return (
    <>
      <PageSEO path="/people" />
      <Title>People</Title>
      <People />
    </>
  )
}

export default PagePeople;
